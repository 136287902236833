import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { MailTypeIds, MailTypes } from 'src/app/core/constants/mail-box-const';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { PopUpService } from 'src/app/core/services/pop-up/pop-up.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { UniversityCommunicationService } from 'src/app/core/services/university-communication/university-communication.service';
import { ShareMailComponent } from 'src/app/views/university-communications/shared/share-mail/share-mail.component';

@Component({
  selector: 'app-email-activity',
  templateUrl: './email-activity.component.html',
  styleUrls: ['./email-activity.component.scss']
})
export class EmailActivityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: string;
  @Input() selected_mails: string;
  @Input() menu_type: any;
  @Input() user_details: any;
  @Input() page_type: string;
  @Output() trigger_reload_data: EventEmitter<any> = new EventEmitter();
  mail_type_ids = MailTypeIds;
  mail_types = MailTypes;
  // user_details: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  show_read: boolean;
  show_unread: boolean;
  show_archive: boolean;
  show_important: boolean;
  show_junk: boolean;
  show_shared: boolean;

  constructor(
    private loaderService: LoaderService,
    private _universitycommunicationService: UniversityCommunicationService,
    private _popUpService: PopUpService,
    private _toasterService: ToasterService,
    private _authService: AuthenticationService,
    public _dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['menu_type']?.currentValue) {
     this.menu_type = changes['menu_type']?.currentValue;
     this.activityButtonVisibility();
    }
  }

  ngOnInit(): void {
    this.activityButtonVisibility();
  }

  public activityConfirmation(type1: any, type2?: any): void {
    if (!this.selected_mails?.length) {
      this._universitycommunicationService.openWarningPopup('');
      return;
    }

    let find_details: any = {};
    if (type2 === 'folder') {
      let find_folder = this.user_details?.mailAllotmentFolders.find(element => element.id === type1);
      find_details = {
        id: Number(find_folder.id),
        type: 'folder',
        name: find_folder?.name,
        msg: `You want to move selected ${this.selected_mails?.length > 1 ? 'mails' : 'mail'} to ${find_folder?.name}?`
      }
    } else {
      if (typeof type1 === 'number') {
        let find_type = this.mail_types.find(element => element.id === type1);
        find_details = {
          id: Number(find_type.id),
          type: '',
          name: find_type?.type,
          msg: `You want to ${find_type?.type?.toLowerCase()} selected ${this.selected_mails?.length > 1 ? 'mails' : 'mail'}?`
        }
      } else {
        if (type1 === 'read') {
          find_details = {
            id: this.menu_type.id,
            name: type1,
            type: 'read',
            msg: `You want to mark read selected ${this.selected_mails?.length > 1 ? 'mails' : 'mail'}?`
          }
        }
        if (type1 === 'unread') {
          find_details = {
            id: this.menu_type.id,
            name: type1,
            type: 'unread',
            msg: `You want to mark unread selected ${this.selected_mails?.length > 1 ? 'mails' : 'mail'}?`
          }
        }
      }
    }

    this._popUpService.showConfirmationPopup(
        'Are you sure?',
        find_details?.msg,
        'Yes',
        (isConfirmed: boolean) => {
            if (isConfirmed) {
                this.bulkUpdate(find_details);
            }
        },
        false,
        'No'
    );
  }

  share() {
    const dialogRef = this._dialog.open(ShareMailComponent, {
      width: '40%',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '100ms',
      data: {
        selected_mails: this.selected_mails
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // this.trigger_reload.emit(true);
          let find_details = {
            id: this.menu_type.id,
            name: 'Share',
            type: 'share',
            share_id: result, 
            msg: `You want to share selected ${this.selected_mails?.length > 1 ? 'mails' : 'mail'}?`
          }
          this.bulkUpdate(find_details);
        }
    });
  }

  bulkUpdate(type?: any) {
    let payload = '';

    if (type?.type === 'read' || type?.type === 'unread') {
      payload = `Id=${this.selected_mails}&type=${type.id}&userId=${this.user_details.id}&isRead=${type?.type === 'read' ? true : false}`;
    } else if (type?.type === 'share') {
      payload = `Id=${this.selected_mails}&type=${type.id}&userId=${this.user_details.id}&sharedUserId=${type?.share_id}`;
    } else {
      payload = `Id=${this.selected_mails}&type=${type.id}&userId=${this.user_details.id}`;
    }

    this.loaderService.showLoader();
    this._universitycommunicationService.bulkUpdate(payload)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {
      this.trigger_reload_data.emit(true);
      this._toasterService.showSuccess('SUCCESS', '');
    }, (err) => {
      this.loaderService.hideLoader();
    })
  }

  getFolders() {
    this.user_details = this._authService.getLoginUserDetails();
  }

  activityButtonVisibility() {
    this.show_read = false;
    this.show_unread = false;
    this.show_archive = false;
    this.show_important = false;
    this.show_junk = false;
    this.show_shared = false;

    let menu_type = Number(this.menu_type.id);
    switch(menu_type) {
      case this.mail_type_ids.inbox:
        this.show_read = true;
        this.show_unread = true;
        this.show_archive = true;
        this.show_important = true;
        this.show_junk = true;
        this.show_shared = true;
        break;
        
      case this.mail_type_ids.sent: 
        this.show_read = false;
        this.show_unread = false;
        this.show_archive = false;
        this.show_important = false;
        this.show_junk = false;
        this.show_shared = false;
        break;

      case this.mail_type_ids.important:
        this.show_read = true;
        this.show_unread = true;
        this.show_archive = true;
        this.show_important = true;
        this.show_junk = true;
        this.show_shared = true;
        break;

      case this.mail_type_ids.archived:
        this.show_read = true;
        this.show_unread = true;
        this.show_archive = false;
        this.show_important = false;
        this.show_junk = true;
        this.show_shared = true;
        break;

      case this.mail_type_ids.junk:
        this.show_read = false;
        this.show_unread = false;
        this.show_archive = false;
        this.show_important = false;
        this.show_junk = false;
        this.show_shared = true;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
